var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navtop',{attrs:{"navList":_vm.handleNavList},on:{"changeCurrent":(e) => {
        _vm.queryType = _vm.navValue[e];
        _vm.resetList(1);
      }}}),_c('div',{staticClass:"order-list mb20"},[_vm._m(0),_vm._l((_vm.purchaseList),function(item,index){return _c('div',{key:index,staticClass:"order-item item-pro mb10"},[_c('div',{staticClass:"pro-title row-between nr lighter"},[_c('div',{staticClass:"row ml20"},[_c('span',{staticClass:"normal mr20"},[_vm._v(_vm._s(item.create_time))]),_c('span',{},[_vm._v("采购单号："+_vm._s(item.order_sn))])])]),_vm._l((item.order_goods),function(items,indexs){return _c('div',{key:indexs,staticClass:"pro-content row"},[_c('div',{staticClass:"flex5 pro-info row"},[_c('el-image',{staticClass:"ml20 mr20",staticStyle:{"width":"64px","height":"64px"},attrs:{"src":items.image,"fit":"cover"}}),_c('router-link',{staticClass:"info-detail flex1 column",attrs:{"to":`/prodetail?id=${items.goods_id}`}},[_c('p',{staticClass:"name line2"},[_vm._v(_vm._s(items.goods_name))])])],1),_c('div',{staticClass:"flex1 pro-num row-center nr normal"},[_vm._v(" X"+_vm._s(items.goods_num)+" ")]),_c('div',{staticClass:"flex2 row-center"},[_c('make-priceformat',{attrs:{"price":items.total_pay_price,"subscriptSize":13,"firstSize":20,"secondSize":20}})],1),_c('div',{staticClass:"flex2 row-center"},[(item.finance_status != '2' && item.manager_status != '2')?_c('div',[(item.audit_text == '')?_c('span',{staticClass:"cartc"},[_vm._v("审核中")]):_vm._e(),(item.audit_text == '已通过')?_c('span',{staticClass:"primary"},[_vm._v("已通过")]):_vm._e(),(item.audit_text == '未通过')?_c('span',{staticClass:"muted"},[_vm._v("未通过")]):_vm._e()]):_vm._e(),(item.finance_status == '2')?_c('div',{staticClass:"xs price"},[_vm._v(" 已驳回(财务) ")]):_vm._e(),(item.manager_status == '2')?_c('div',{staticClass:"xs price"},[_vm._v(" 已驳回(经理) ")]):_vm._e()]),_c('div',{staticClass:"flex2 row-center"},[(
              item.pay_btn &&
              item.finance_status == '0' &&
              item.manager_status == '1'
            )?_c('button',{staticClass:"status-btn white row-center",attrs:{"type":"primary"},on:{"click":function($event){(_vm.auditBox.order_id = item.id),
                (_vm.auditBox.type = 1),
                (_vm.dialogVisible = true)}}},[_vm._v(" 待审核(财务) ")]):_vm._e(),(item.pay_btn && item.manager_status == '0')?_c('button',{staticClass:"status-btn white row-center",attrs:{"type":"primary"},on:{"click":function($event){(_vm.auditBox.order_id = item.id),
                (_vm.auditBox.type = 2),
                (_vm.dialogVisible = true)}}},[_vm._v(" 待审核(采购) ")]):_vm._e()])])})],2)})],2),_c('div',{staticClass:"row-end"},[(_vm.purchaseList.length)?_c('make-pagination',{attrs:{"orderObject":_vm.orderObject},on:{"resetList":_vm.resetList}}):_vm._e()],1),_c('el-dialog',{attrs:{"title":"订单审核","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-radio-group',{staticClass:"mb20",model:{value:(_vm.auditBox.status),callback:function ($$v) {_vm.$set(_vm.auditBox, "status", $$v)},expression:"auditBox.status"}},[_c('el-radio',{attrs:{"label":1,"value":1}},[_vm._v("通过审核")]),_c('el-radio',{attrs:{"label":2,"value":2}},[_vm._v("驳回审核")])],1),(_vm.auditBox.status == 2)?_c('el-input',{attrs:{"type":"textarea","rows":4,"placeholder":"请输入驳回内容（驳回时必填）","resize":"none"},model:{value:(_vm.auditBox.reason),callback:function ($$v) {_vm.$set(_vm.auditBox, "reason", $$v)},expression:"auditBox.reason"}}):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.setAudit()}}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-item item-th row nr lighter"},[_c('div',{staticClass:"flex5"},[_c('span',{staticClass:"pd"},[_vm._v("商品")])]),_c('div',{staticClass:"flex1 row-center"},[_vm._v("数量")]),_c('div',{staticClass:"flex2 row-center"},[_vm._v("采购金额")]),_c('div',{staticClass:"flex2 row-center"},[_vm._v("审核状态")]),_c('div',{staticClass:"flex2 row-center"},[_vm._v("操作")])])
}]

export { render, staticRenderFns }